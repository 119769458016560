$(window).on('load', function () {
    initSliderHeight();
    initMatchHeight();
});

$(function () {
    initNav();
    initSlick();
    initSlick2();
    initSlick3();
    initScroll();
    initScrollContact();
    initCookiebox();
    initVerzuim();
});

function initNav() {
    $btn_navigation = $('header .nav-toggle');
    $nav = $('header nav');

    $btn_navigation.click(function (e) {
        e.stopPropagation();
        e.preventDefault();

        if ($(window).width() < 980) {
            $nav.slideToggle();
        }
    });

    if ($nav.find('ul').length > 0) {
        $li = $nav.find('li');

        $li.has('ul').children('a').click(function (e) {

            $(this).closest('ul').children('li').find('ul').each(function (i, ul) {
                if ($(ul) != $(this).next('ul')) {
                    $(this).prev('a').children('span').removeClass('toggle')
                    $(ul).slideUp();
                }
            });

            if ($(this).next('ul').is(':hidden')) {
                e.preventDefault();
                $(this).next('ul').slideDown();
                $(this).children('span').addClass('toggle')
            }
        });
    }

    $(window).scroll(function () {
        if ($(this).scrollTop() > 0) {
            $('.nav_wrapper').addClass('fixed');
        } else {
            $('.nav_wrapper').removeClass('fixed');
        }
    });
}

function initSliderHeight() {
    if ($('.carousel-wrapper .slide-wrapper').length > 0) {
        $('.carousel-wrapper').find('.container-fluid').css({
            height: $('.carousel-wrapper').find('img').outerHeight()
        });

        $(window).resize(function () {
            $('.carousel-wrapper').find('.container-fluid').css({
                height: $('.carousel-wrapper').find('img').outerHeight()
            });
        });
    }
}

//header
function initSlick() {
    if ($('.carousel-wrapper .slide-wrapper').length > 0) {
        $('.carousel-wrapper .slide-wrapper').slick({
            infinite: true,
            dots: false,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1
        });
    }
}

//homepage testimonials 
function initSlick2() {
    if ($('.carousel-testimonials').length > 0) {
        $('.carousel-testimonials').slick({
            infinite: true,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: '<i class="icon icon-arrow-left prev"></i>',
            nextArrow: '<i class="icon icon-arrow-right next"></i>',
        });
    }
}

//testimonial page slider
function initSlick3() {
    if ($('.carousel-testimonial').length > 0) {
        $('.carousel-testimonial').slick({
            infinite: true,
            dots: false,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1
        });

        $('.prev').on('click', function () {
            $('.carousel-testimonial').slick('slickPrev');
        }); 
        $('.next').on('click', function () {
            $('.carousel-testimonial').slick('slickNext');
        });

        var url = window.location.href;
        if (url.split('#')[1] === 'Mark') {
            $('.carousel-testimonial').slick("slickGoTo", 1, true);
        } else if (url.split('#')[1] === 'Ingrid') {
            $('.carousel-testimonial').slick("slickGoTo", 2, true);
        }

    }
}

function initScroll() {
    $('.button-overlay').on('click', function (e) {
        e.preventDefault();

        $('html, body').animate({
            scrollTop: $("#content1").offset().top
        }, 1000);
    });
}

function initScrollContact() {
    $('.contactscroll').on('click', function (e) {
        e.preventDefault();

        $('html, body').animate({
            scrollTop: $("#footer").offset().top
        }, 1000);
    });
}

function initMatchHeight() {
    $('.matchHeight').each(function () {
        $(this).find('.match').matchHeight({
            byRow: false
        });
    });
}

function initCookiebox() {
    if ($('#cookiebox').length) {
        setTimeout(function () {
            $('#cookiebox .cookies').css({
                right: '30px'
            });
        }, 500);

        $('#cookiebox .cookies .btn-save').click(function () {
            akkoord();
        });

        $('#cookiebox .cookies .settings').click(function () {
            $('#cookiebox .cookies').css({
                right: '-630px'
            });

            setTimeout(function () {
                $('#cookiebox > .settings').css({
                    right: '30px'
                });
            }, 700);
        });

        $('#cookiebox .settings .btn-save').click(function () {
            setLevel();
        });
    }
}

function akkoord() {
    setCookie('cookie_level', 3, 365);

    $('#cookiebox > .cookies').css({
        right: '-410px'
    });

    setTimeout(function () {
        location.reload();
    }, 300);
}

function setLevel() {
    var options = document.querySelectorAll('#cookiebox .settings input');

    for (var i = 0; i < options.length; i++) {
        if (options[i].checked) {
            setCookie('cookie_level', options[i].getAttribute('value'), 365);

            $('#cookiebox > .settings').css({
                right: '-410px'
            });

            setTimeout(function () {
                location.reload();
            }, 300);
        }
    }
}

function herzien(e) {
    e.preventDefault();
    setCookie('cookie_level', 4, 365);

    location.reload();
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}

function removeCookie(name) {
    if (get_cookie(name)) {
        document.cookie = name + '=; Max-Age=-99999999;';
    }
}

function initVerzuim() {
    if ($('#verzuim').length) {
        $('ul.items li .popup .close').on('click', function(e){
            e.preventDefault();
            $(this).parent().hide();
        });

        $('ul.items li > a').on('click', function (ev) {
            ev.preventDefault();
            popup = $(this).next();

            $('ul.items li .popup').hide();
            popup.show();

            $('html, body').animate({
                scrollTop: $('ul.items').offset().top
            }, 1000);
        });
    }
}